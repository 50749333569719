<template>
  <v-card
    elevation="0"
    class="pa-5"
    height="100vh"
    style="text-align:center;"
  >
    <h2>
      <span>{{ $t('courierSet.loading') }}</span>
    </h2>
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </v-card>
</template>

<script>
import { oidcSettings } from '@/oidc';
import { mapActions } from 'vuex';
import { allegroAuthorizationService } from '@/services/spedition/allegroAuthorization';

export default {
  async mounted() {
    const { code, nubo_params } = this.$route.query;
    const [clientSecret, clientId, environment] = nubo_params.split(',');
    const redirectUri = `${oidcSettings.post_logout_redirect_uri}allegroAuthorization?nubo_params=${clientSecret},${clientId},${environment}`;

    const payload = {
      allegroEnv: environment,
      code,
      redirectUri,
      clientId,
      clientSecret,
    };
    try {
      const { data } = await allegroAuthorizationService.getAllegroAuthorizationToken(payload);
      this.setAllegroAuthorization({
        authorizationData: {
          ...data, clientSecret, clientId, environment,
        },
      });
      this.$router.push({
        name: 'user-configuration-set-allegro-new',
        params: {
          speditor: 'Allegro',
        },
      });
    } catch (err) {
      const { response = '' } = err;
      const message = response?.data?.message || 'Błąd';
      this.$snotify.error(message);
      console.warn(err);
    }
  },
  methods: { ...mapActions('spedition', ['setAllegroAuthorization']) },
};
</script>
